<template>
  <div class="contents">
    <audio
      id="bgmSound"
      :src="`${commonPath}sounds/bgm.mp3`"
      loop="true"
    />
    <div class="top-menu">
      <img
        class="top-menu-logo"
        :src="`${commonPath}${menuContent.logoPath}`"
        alt="MIT ロゴ画像"
        @click="openNewWindow(menuContent.homeUrl, false)"
      >
      <div class="top-menu-right">
        <div
          class="menu-button box-button"
          @click="openInNewWindow(menuContent.contactUrl)"
        >
          <div class="button-content">
            <i class="fas fa-question-circle" />
            <p class="menu-button-text">操作方法</p>
          </div>
        </div>
        <div
          class="menu-button"
          @click="(bgmStatus)?setBgmStatus(false):setBgmStatus(true);"
        >
          <div class="button-content">
            <template v-if="bgmStatus">
              <div  class="button-content circle-button volume--on">
                <i class="fas fa-volume-up" />
                <div>ON</div>
              </div>
            </template>
            <template v-else>
              <div class="button-content circle-button volume--off">
                <i class="fas fa-volume-mute" />
                <div>OFF</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="vr-scene">
      <a-scene id="scene">
        <a-assets
          v-if="pageId === undefined"
          id="assets-area"
        >
          <a-image
            v-for="(face, index) in faceList"
            :id="index"
            :key="index"
            :src="`${routePath}images/${face}`"
          />
          <video
            v-if="loadFlag"
            id="video1"
            :src="`${commonPath}movie/dummy1.mp4`"
            playsinline
            autoplay
            muted
            loop
            @loadeddata="onloadedVideo"
          />
          <video
            v-if="loadFlag"
            id="video2"
            :src="`${commonPath}movie/dummy2.mp4`"
            playsinline
            autoplay
            muted
            loop
            @loadeddata="onloadedVideo"
          />
          <a-image
            id="A-sky-sub"
            :src="`${routePath}images/A-sky-sub.jpg`"
            alt="室内の様子"
          />
        </a-assets>
        <RoomScene
          ref="room"
          :image-route-path="routePath + 'images/'"
          :glb-route-path="routePath + 'glb/'"
          :json-data="jsonData"
          @setGlbData="setGlbData"
          @loadFlag="loadFlag=true"
          @setConfirmWindow="setConfirmWindow"
        />
        <ControlButton 
          v-if="roomRig && roomCamera && isTouchDevice"
          :json-rotation-y="jsonRotationY"
          :room-rig="roomRig"
          :room-camera="roomCamera"
        />
        <!-- アバター -->
        <a-entity
          v-if="pageId === undefined"
          id="avatar-parts"
        >
        <a-entity
            v-for="(avatar, index) in avatarData"
            :id="index"
            :key="index"
            :position="avatar.position"
            :rotation="avatar.rotation"
            @click="setConfirmWindow(index)"
            animation="property: rotation; from: 0 160 0; to: 0 200 0; dur: 5000; easing: linear; dir:alternate; loop: true"
          >
            <a-entity
              :gltf-model="avatar.model"
              rotation="0 180 0"
            />
            <a-image
              :src="`#${avatar.face}`"
              position="0 1.03 0.175"
              width="0.6"
              height="0.34"
            />
          </a-entity>
        </a-entity>
        <!-- 動画 -->
        <a-entity
          id="movie-parts"
        >
          <a-video
            v-if="loadFlag"
            src="#video1"
            position="-3.312 1.03 -3.805"
            rotation="0 60 0"
            width="0.6"
            height="0.34"
          />
          <a-video
            v-if="loadFlag"
            src="#video2"
            position="-16 1.03 -8.732"
            rotation="0 60 0"
            width="0.6"
            height="0.34"
          />
        </a-entity>
        <!-- 天球画像 -->
        <a-entity
          v-if="pageId === undefined"
          id="sphere-parts"
        >
          <a-sphere 
            src="#A-sky-sub" 
            material="side: back; repeat: -1 1" 
            radius="5" 
            position="-25 2 -7.742" 
            rotation="0 188 0"
          />
        </a-entity>
      </a-scene>
    </div>
    <LoadingView v-if="!loadFlag" />
    <SoundPermission
      v-if="soundPermissionFlag"
      @sound-permission="clickSoundPermission()"
    />
    <ConfirmWindow
      v-if="confirmWindowFlag"
      :target-title="targetTitle"
      :target-url="targetUrl"
      :window-open="windowTabFlag"
      @close-window="confirmWindowFlag=false"
    />
  </div>
</template>

<script>
import utilsMixin from '../mixins/utils'
import LoadingView from '../components/LoadingView'
import SoundPermission from '../components/SoundPermission'
import ConfirmWindow from '../components/ConfirmWindow'
import RoomScene from '../components/RoomScene'
import ControlButton from '../components/ControlButton'
import settingJson from '../assets/setting-mit.json';

export default {
  name: 'MitView',
  components: {
    RoomScene,
    ControlButton,
    LoadingView,
    SoundPermission,
    ConfirmWindow
  },
  mixins: [utilsMixin],
  props: {},
  data() {
    return {
      title: 'MIT メタサイト',  // ページタイトル
      description: 'MIT イベント入口メタサイトです。',  // ページ説明
      loadFlag: false,                                    // ロード画面用フラグ
      confirmWindowFlag: false,                           // 遷移先確認画面表示フラグ
      soundPermissionFlag: false,                         // 音声許可ウィンドウ表示フラグ
      targetUrl: '',                                      // 遷移先URL
      windowTabFlag: true,                                // 別タブ起動フラグ true->別タグ
      allJsonData: settingJson,                           // 全てのページの設定情報
      jsonData: '',                                       // ページごとの設定情報
      pageId: this.$route.params.page,                    // ページID
      prevPageId: this.$route.query.prev,                 // 一つ前のページID
      commonPath: '/assets/mit/',         // 各データの共通パス
      routePath: '/assets/mit/',          // 各データのルートパス
      secondaryRoutePath: '/assets/mit-page/',  // 子階層の各データのルートパス
      glbData: {},                                        // 子Vueで取得したGLBデータ
      delWarpGlbList: [],
      faceList: {   // 顔動画
        man: 'man.jpg',
      },
      avatarData: { // アバターの情報
        'casual-man': {
          model: '/glb/simple-avatar/casual-man.glb',
          type: 'video',
          face: 'man',
          position: '-7.694 0 -9.198',
          rotation: '0 180 0'
        },
      },    
      pageRig: {    // 子階層ページのGLBごとのrig情報
      },
      menuContent: {
        logoPath: 'images/menu-logo.jpg',
        homeUrl: '/#/mit',
        contactUrl: 'https://www.vr-lite.net/#/qa?user=1'
      },
      windowActive: true, // ウィンドウがアクティブかどうかのフラグ
      bgmStatusBeforeDeactivation: true, // ウィンドウが非アクティブだったときのBGMの状態
      jsonRotationY: 0, // rigカメラのY軸回転角度
      roomRig: null, // カメラリグの参照
      roomCamera: null // カメラの参照
    }
  },
  computed: {
    firstAccess() {
      // 初回アクセスフラグを取得
      return this.$store.getters['CampusStatus/getFirstAccess'];
    },
    bgmStatus() {
      // BGM許可ステータスを取得
      return this.$store.getters['CampusStatus/getBgmStatus'];
    },
    isTouchDevice() {
      // タッチデバイス判定を取得
      return this.$store.getters['CampusStatus/getTouchDeviceStatus'];
    }
  },
  watch: {
    /*******************************************
     * BGM許可ステータスに変更がある場合
     ******************************************/
     async bgmStatus(newBool) {
      if (newBool !== this.bgmStatus) {
        // BGMコントロール処理を呼び出し
        this.setBgmStatus(newBool);
      }
    },
    /*******************************************
     * Loadフラグが更新されたらアニメーションを設定
     ******************************************/
    async loadFlag(newBool) {
      // 音声許可ポップアップを表示する判定
      if(this.firstAccess) {
        // 初回アクセスでiPhoneではない場合のみ設定
        if(!/[ \(]iP/.test(navigator.userAgent)) this.soundPermissionFlag = true;
      }
      if(newBool) {
        for (let property in this.glbData.animation) {
          // アニメーションを設定
          await this.setAnimation(property);
        }
        // リンク情報の存在しないワープGLBを非表示
        this.delWarpGlbList.forEach((elem, index) => {
          let removeGlbWarp = document.getElementById(elem);
          removeGlbWarp.setAttribute('visible', false);
        });
      }
    },
    /*******************************************
     * ウィンドウのアクティブ状態の変更を監視
     ******************************************/
    windowActive(newActiveState) {
      // ウィンドウが非アクティブになったとき
      if (!newActiveState) {
        // ウィンドウが非アクティブになる直前のBGMの状態を保存
        this.bgmStatusBeforeDeactivation = this.bgmStatus;
        // BGMをミュートにする
        this.setBgmStatus(false);
      } else {
        // ウィンドウがアクティブになったとき
        // ウィンドウが非アクティブだったときのBGMの状態に戻す
        this.setBgmStatus(this.bgmStatusBeforeDeactivation);
      }
    }
  },
  created() {
    // ページIDが存在しない場合eventの処理を行う
    if(this.pageId === undefined) {
      // eventの情報を設定
      this.jsonData = this.allJsonData.event;
      // エレベーターから戻ってきた場合の出現位置を設定
      if(this.prevPageId !== undefined) {
        let prev = this.prevPageId.replace(/[^a-z]/gi, '');
        this.jsonData.rig = this.jsonData.prevRig[prev];
      }
    } else if(this.allJsonData[this.pageId] === undefined){
      // ページが存在しない場合はメインフロアを表示
      this.jsonData = this.allJsonData.event;
      this.$router.push({name: 'mit'});
      setTimeout(function () {
        location.reload();
      }, 10);
    } else {
      // ルートパスを上書き
      this.routePath = this.secondaryRoutePath;
      // ページ情報を設定
      this.jsonData = this.allJsonData[this.pageId];
      // ページidから数字を抽出
      let prevNum = 0;
      let pageNum = this.pageId.replace(/[^0-9]/gi, '');
      if(this.prevPageId !== undefined) prevNum = this.prevPageId.replace(/[^0-9]/gi, '');
      // prevが現在のページより大きい数の場合rigを上書き
      if(pageNum < prevNum) {
        // 前のページから戻ってきた場合
        this.jsonData.rig = this.pageRig[this.jsonData.modelName].prev;
      } else {
        // それ以外
        this.jsonData.rig = this.pageRig[this.jsonData.modelName].current;
      }
    }
    // カメラ座標を設定
    this.jsonData['camera'] = this.camera;
  },
  mounted() {
    // BGMコントロール処理を呼び出し
    this.bgmControl();
    // Vue.jsのDOM更新が完了した後に実行
    this.$nextTick(() => {
      // タイトルと説明、OGPを設定
      this.changePageContent();
      // noindexメタタグを追加
      this.addNoIndexMeta();
    });
    // // ページがメインフロアの場合、顔動画を再生
    // if(this.pageId === undefined) {
    //   for(const video in this.faceList){
    //     let videoElement = document.getElementById(video);
    //     videoElement.play();
    //   }
    // }
    // コントロールボタン操作に必要な情報を設定
    this.jsonRotationY = this.jsonData.rig.rotation.y; // jsonDataからrigのY軸回転角度を取得しjsonRotationYに設定
    this.roomRig = this.$refs.room.$refs.rig; // roomコンポーネントからrigエレメントを取得しroomRigに設定
    this.roomCamera = this.$refs.room.$refs.camera; // roomコンポーネントからcameraエレメントを取得しroomCameraに設定
    /*******************************************
     * ウィンドウのフォーカスイベントを監視
     ******************************************/
    window.addEventListener('focus', () => {
      this.windowActive = true;
    });
    // ウィンドウのブラーイベントを監視
    window.addEventListener('blur', () => {
      this.windowActive = false;
    });
    /*******************************************
     * ワープゾーンのポジション設定
     ******************************************/
    const positionChanged = (newPosition) => {
      // 設定されているワープゾーンの数だけ繰り返しチェック
      for (let property in this.jsonData.warpList) {
        // 設定ファイルからワープゾーンのポジションを取得
        let xWarpSpot = Math.ceil(this.jsonData.warpList[property].position.x);
        let yWarpSpot = Math.ceil(this.jsonData.warpList[property].position.y);
        let zWarpSpot = Math.ceil(this.jsonData.warpList[property].position.z);
        // ワープゾーンに来たら確認ポップアップを起動(原点から±1m以内の範囲)
        if(
          (newPosition.x >= xWarpSpot - 1 && newPosition.x <= xWarpSpot + 1) &&
          (newPosition.y >= yWarpSpot - 1 && newPosition.y <= yWarpSpot + 1) &&
          (newPosition.z >= zWarpSpot - 1 && newPosition.z <= zWarpSpot + 1)
        ) {
          // ポップアップの処理を呼び出し
          this.setWarpConfirmWindow(property);
        }
      }
    };
    // ポジションを見張る処理
    window.AFRAME.registerComponent('position-listener', {
      tick() {
        // 現在のポジションを取得
        const newValue = this.el.getAttribute('position');
        // 小数点を四捨五入
        const newPosition = {
          x: Math.ceil(newValue.x),
          y: Math.ceil(newValue.y),
          z: Math.ceil(newValue.z)
        }
        // 文字列に置き換え
        const stringCoords = window.AFRAME.utils.coordinates.stringify({
          x: newPosition.x,
          y: newPosition.y,
          z: newPosition.z
        });
        // ポジションが変更しているかチェック
        if (this.lastValue !== stringCoords) {
          this.lastValue = stringCoords;
          // 変更がある場合はワープの位置をチェック
          positionChanged(newPosition);
        }
      }
    });
    // /*******************************************
    //  * 外部のjsファイルを読み込む
    //  * zoho CHAT
    // ******************************************/
    // // スクリプト要素を作成
    // const script1 = document.createElement('script');
    // script1.innerHTML = `
    //   window.$zoho=window.$zoho || {};$zoho.salesiq=$zoho.salesiq||{ready:function(){}}
    // `;
    // // スクリプト要素を作成
    // const script2 = document.createElement('script');
    // script2.src = 'https://salesiq.zohopublic.jp/widget?wc=a2fdcc7092a3b6dcbe9f18d98c8a17cd659631379b759e04fd52d7283bee86e4e5714d4e195a3824c7e89a3eb7f0dd9c';
    // script2.id = "zsiqscript";
    // script2.defer = true;
    // // ドキュメントのヘッドにスクリプトを追加
    // document.head.appendChild(script1);
    // document.head.appendChild(script2);
  },
  methods: {
    /*******************************************
     * OGPを設定
     ******************************************/
    // ogImagePathを生成する
    ogImagePath() {
      const uri = new URL(window.location.href);
      if(this.pageId === undefined) {
        return `${uri.origin}${this.routePath}images/og_image.png`;
      } else {
        return `${uri.origin}${this.secondaryRoutePath}images/og_image-${this.pageId}.png`;
      }
    },
    /*******************************************
     * BGM許可をクリックした時
     ******************************************/
     clickSoundPermission() {
      // 許可フラグを書き換え
      this.soundPermissionFlag = false;
      // 初回アクセスの場合は書き換え
      if(this.firstAccess) this.$store.dispatch('CampusStatus/setFirstAccess', false);
      this.setBgmStatus(true);
    },
    /*******************************************
     * BGM許可ステータスを設定
     ******************************************/
     setBgmStatus(newBool) {
      this.$store.dispatch('CampusStatus/setBgmStatus', newBool);
      this.soundPermissionFlag = false;
      // BGMコントロール処理を呼び出し
      this.bgmControl();
    },
    /*******************************************
     * 動画のロードが完了したとき
     ******************************************/
     async onloadedVideo(event) {
      let avatarVideo = event.target;
      avatarVideo.muted = true;  // Ensure the video is muted
      try {
        await avatarVideo.play();
      } catch (err) {
        console.log("Failed to autoplay video:", err);
        // Handle autoplay error, for example, by showing a play button
      }
    },
    /*******************************************
     * 音楽のBGMの再生処理
     * フラグに変更があれば再生or一時停止処理
     ******************************************/
    async bgmControl() {
      const bgmSound = document.getElementById('bgmSound');
      if(Boolean(this.bgmStatus)) {
      // 再生処理
        try {
          // BGMを再生
          await bgmSound.play();
        } catch(err) {
          // 再生に失敗した場合はフラグを書き換え
          this.setBgmStatus(false);
          // iPhoneではない場合のみ設定
          if(!/[ \(]iP/.test(navigator.userAgent)) this.soundPermissionFlag = true;
        }
      } else {
        // 一時停止処理
        bgmSound.pause();
      }
    },
    /*******************************************
     * 子から受け取った建物データを設定
     ******************************************/
    setGlbData(glbData) {
      this.glbData = glbData;
    },
    /*******************************************
     * 別タブでURLを起動
     ******************************************/
    openNewWindow(target, windw = true) {
      if(windw) {
        window.open(target);
      } else {
        location.href = target
        location.reload();
      }
    },
    /*******************************************
		 * 別ウインドウでURLを起動
		 ******************************************/
		openInNewWindow(target) {
			// 新しいウィンドウのサイズ
			const newWindowWidth = 800;
    	const newWindowHeight = 600;
			// 画面サイズを取得
			const screenWidth = window.innerWidth;
			const screenHeight = window.innerHeight;
			// 右下に位置を設定
			const left = screenWidth - newWindowWidth; // 画面の右端からウィンドウの幅を引いた位置
			const top = screenHeight - newWindowHeight; // 画面の下端からウィンドウの高さを引いた位置
			window.open(target, '_blank', `width=${newWindowWidth},height=${newWindowHeight},top=${top},left=${left}`);
		},
    /*******************************************
     * 画面遷移のConfirm画面呼び出し
     ******************************************/
    setConfirmWindow(target) {
      // 設定ファイルからタイトルとURLを取得
      this.targetTitle = this.jsonData.linkList[target].title;
      this.targetUrl = this.jsonData.linkList[target].url;
      // confirmウィンドウを起動
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * ワープのConfirm画面呼び出し
     ******************************************/
     setWarpConfirmWindow(target) {
      // URL形式
      if (this.jsonData.warpList[target].url.indexOf('://') === -1) {
        // 自サイトの場合ホストを設定
        this.targetUrl = window.location.protocol + '//' + window.location.host + '/#';
        this.targetUrl += this.jsonData.warpList[target].url;
        // pageIDがある場合はパラメーターに設定
        if(this.pageId !== undefined) this.targetUrl += '?prev=' + this.pageId;
        this.windowTabFlag = false;
      } else {
        // 他サイトの場合はそのままURLを設定
        this.targetUrl =  this.jsonData.warpList[target].url;
      }
      // タイトルを設定
      this.targetTitle = this.jsonData.warpList[target].title;
      // confirmウィンドウを起動
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * パーツごとにアニメーションを設定する
     ******************************************/
    setAnimation(animationName) {
      let animationElement = document.getElementById(animationName);
      // ドア左の開くアニメーション
      if (animationElement.id === 'animation-door-l') {
        animationElement.setAttribute('animation', 'property: object3D.position.x;to: 1.1;dir: alternate;dur: 2000;loop: false');
      }
      // ドア右の開くアニメーション
      if (animationElement.id === 'animation-door-r') {
        animationElement.setAttribute('animation', 'property: object3D.position.x;to: -1.1;dir: alternate;dur: 2000;loop: false');
      }
      // アバター1 アニメーション
      if (animationElement.id === 'animation-avatar-1') {
        animationElement.setAttribute('animation-mixer', 'loop:true;clampWhenFinished:true;clip:avatar-1;');
      }
      // アバター2 アニメーション
      if (animationElement.id === 'animation-avatar-2') {
        animationElement.setAttribute('animation-mixer', 'loop:true;clampWhenFinished:true;clip:avatar-2;');
      }
      // 建物内の矢印を前後に動かす
      if (animationElement.id === 'animation-nobody-arrow') {
        animationElement.setAttribute('animation', 'property: object3D.position.z;to: -0.5;dir: alternate;dur: 1000;loop: true');
      }
      // ワープ(渦巻)のアニメーション
      if(animationElement.id.indexOf('animation-warp') !== -1) {
        // 原点にあるものは削除
        animationElement.remove();
        // glbファイルの保存先URLを取得
        const glbWarpPath = this.glbData.animation[animationElement.id].path;
        // warp glbのファイル名の最後の文字を取得(サイズが一致しない場合は表示しない判定に利用)
        const glbWarpSize = animationElement.id.charAt(animationElement.id.length - 1);
        // ワープゾーン(土台)のGLB名を全て取得(すでに設定されている場合は取得しない)
        if(this.delWarpGlbList.length === 0) {
          this.delWarpGlbList = Object.keys(this.glbData.common).filter( (key) => {
            return key.match(/^nobody-warp-[0-9]/);
          });
        }
        // ワープGLBを配置する処理
        const scene = document.getElementById('scene');
        // 設定ファイルのワープリストが存在するだけ繰り返す
        for(let property in this.jsonData.warpList) {
          let warpData = this.jsonData.warpList[property];
          // ワープのサイズが一致しない場合は後続処理を行わない
          let warpSize = warpData.size.charAt(0);
          if(glbWarpSize !== warpSize) continue;
          // リンク先情報がある場合は削除リストから除外
          this.delWarpGlbList = this.delWarpGlbList.filter(function(glb) {
            return !glb.match(property);
          });
          // ワープのentityを作成して配置
          let warp = document.createElement('a-entity');
          warp.setAttribute('id', property);
          warp.setAttribute('static-body', 'type: dynamic; mass: 5; shape:none;');
          // glbWarpPathが存在する場合はモデルを設定
          if (glbWarpPath) {
            warp.setAttribute('gltf-model', glbWarpPath);
          }
          warp.setAttribute('position', warpData.position);
          warp.setAttribute('animation', 'property: rotation; from: 0 0 0; to: 0 360 0; dur: 800; easing: linear; loop: true');
          scene.appendChild(warp);
        }
      }
    }
  }
}
</script>

<style scoped>
.contents {
  height: 100vh;
}
.vr-scene {
  height: 92vh;
}
.top-menu {
  display: grid;
	grid-template-columns: 120px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  align-items: center;
  padding: 10px;
}
.top-menu-logo {
  cursor: pointer;
  width: auto;
  max-height: 60px;
}
.top-menu-right {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-left: auto;
}
.menu-button{
  cursor: pointer;
  background-color: #fff;
  margin: auto 10px auto 0;
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s;
}
.menu-button:last-child {
	height: 50px;
	margin-right: 0;
}
.button-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-button {
  color: #fff;
  line-height: 24px;
	font-weight: bold;
  border: 1px solid #8f3239;
  border-radius: 50px;
  padding: 8px 32px;
	transition: all ease 0.2s;
  background-color: #8f3239;
}
.box-button:hover {
  color: #fff;
  background-color: #40161A;
  border-color: #40161A;
}
.box-button:hover .menu-button-text {
  color: #fff;
}
.box-button .button-content {
  letter-spacing: 0.5px;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 0.5em;
} 
.circle-button {
  width: 50px;
  line-height: 0.85em;
  border: thin solid #202020;
  border-radius: 50%;
}
.circle-button:hover {
  color: #fff;
  background-color: #202020;
}
.circle-button.volume--on {
  color:#fff;
  background-color: #202020;
}
.circle-button-text {
  font-size: 0.35em;
  white-space: nowrap;
}
@media screen and (max-width:767px){
  .top-menu-logo {
    max-width: 180px;
  }
}
@media screen and (max-width:428px){
  /* SP 縦画面 */
  .top-menu {
    grid-template-columns: 100px 1fr;
    padding: 10px 5px;
  }

	.box-button {
    padding: 8px 16px;
    margin-right: 5px;
	}

	.top-menu-logo {
		max-width: 100px;
	}
}
</style>